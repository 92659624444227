exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-chronological-tsx": () => import("./../../../src/pages/chronological.tsx" /* webpackChunkName: "component---src-pages-chronological-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-articles-20191021-introduction-md": () => import("./../../../src/templates/articleTemplate.tsx?__contentFilePath=/home/runner/work/kidney-donation-diary/kidney-donation-diary/src/articles/20191021-introduction.md" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-articles-20191021-introduction-md" */),
  "component---src-templates-article-template-tsx-content-file-path-src-articles-20191022-getting-up-to-speed-md": () => import("./../../../src/templates/articleTemplate.tsx?__contentFilePath=/home/runner/work/kidney-donation-diary/kidney-donation-diary/src/articles/20191022-getting-up-to-speed.md" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-articles-20191022-getting-up-to-speed-md" */),
  "component---src-templates-article-template-tsx-content-file-path-src-articles-20191024-lots-and-lots-of-waiting-md": () => import("./../../../src/templates/articleTemplate.tsx?__contentFilePath=/home/runner/work/kidney-donation-diary/kidney-donation-diary/src/articles/20191024-lots-and-lots-of-waiting.md" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-articles-20191024-lots-and-lots-of-waiting-md" */),
  "component---src-templates-article-template-tsx-content-file-path-src-articles-20191028-the-blood-work-results-are-in-md": () => import("./../../../src/templates/articleTemplate.tsx?__contentFilePath=/home/runner/work/kidney-donation-diary/kidney-donation-diary/src/articles/20191028-the-blood-work-results-are-in.md" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-articles-20191028-the-blood-work-results-are-in-md" */),
  "component---src-templates-article-template-tsx-content-file-path-src-articles-20191105-on-my-way-md": () => import("./../../../src/templates/articleTemplate.tsx?__contentFilePath=/home/runner/work/kidney-donation-diary/kidney-donation-diary/src/articles/20191105-on-my-way.md" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-articles-20191105-on-my-way-md" */),
  "component---src-templates-article-template-tsx-content-file-path-src-articles-20191107-the-last-round-of-tests-are-done-mdx": () => import("./../../../src/templates/articleTemplate.tsx?__contentFilePath=/home/runner/work/kidney-donation-diary/kidney-donation-diary/src/articles/20191107-the-last-round-of-tests-are-done.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-articles-20191107-the-last-round-of-tests-are-done-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-articles-20191126-another-24-hour-urine-collection-md": () => import("./../../../src/templates/articleTemplate.tsx?__contentFilePath=/home/runner/work/kidney-donation-diary/kidney-donation-diary/src/articles/20191126-another-24-hour-urine-collection.md" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-articles-20191126-another-24-hour-urine-collection-md" */),
  "component---src-templates-article-template-tsx-content-file-path-src-articles-20191206-finally-approved-md": () => import("./../../../src/templates/articleTemplate.tsx?__contentFilePath=/home/runner/work/kidney-donation-diary/kidney-donation-diary/src/articles/20191206-finally-approved.md" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-articles-20191206-finally-approved-md" */),
  "component---src-templates-article-template-tsx-content-file-path-src-articles-20191210-meeting-the-recipient-md": () => import("./../../../src/templates/articleTemplate.tsx?__contentFilePath=/home/runner/work/kidney-donation-diary/kidney-donation-diary/src/articles/20191210-meeting-the-recipient.md" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-articles-20191210-meeting-the-recipient-md" */),
  "component---src-templates-article-template-tsx-content-file-path-src-articles-20191214-surgery-date-set-md": () => import("./../../../src/templates/articleTemplate.tsx?__contentFilePath=/home/runner/work/kidney-donation-diary/kidney-donation-diary/src/articles/20191214-surgery-date-set.md" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-articles-20191214-surgery-date-set-md" */),
  "component---src-templates-article-template-tsx-content-file-path-src-articles-20191229-met-the-recipients-family-md": () => import("./../../../src/templates/articleTemplate.tsx?__contentFilePath=/home/runner/work/kidney-donation-diary/kidney-donation-diary/src/articles/20191229-met-the-recipients-family.md" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-articles-20191229-met-the-recipients-family-md" */),
  "component---src-templates-article-template-tsx-content-file-path-src-articles-20191230-finished-the-pre-op-appointment-mdx": () => import("./../../../src/templates/articleTemplate.tsx?__contentFilePath=/home/runner/work/kidney-donation-diary/kidney-donation-diary/src/articles/20191230-finished-the-pre-op-appointment.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-articles-20191230-finished-the-pre-op-appointment-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-articles-20200101-surgery-postponed-md": () => import("./../../../src/templates/articleTemplate.tsx?__contentFilePath=/home/runner/work/kidney-donation-diary/kidney-donation-diary/src/articles/20200101-surgery-postponed.md" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-articles-20200101-surgery-postponed-md" */),
  "component---src-templates-article-template-tsx-content-file-path-src-articles-20200107-surgery-date-set-part-2-md": () => import("./../../../src/templates/articleTemplate.tsx?__contentFilePath=/home/runner/work/kidney-donation-diary/kidney-donation-diary/src/articles/20200107-surgery-date-set-part-2.md" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-articles-20200107-surgery-date-set-part-2-md" */),
  "component---src-templates-article-template-tsx-content-file-path-src-articles-20200108-the-recipient-needs-your-help-md": () => import("./../../../src/templates/articleTemplate.tsx?__contentFilePath=/home/runner/work/kidney-donation-diary/kidney-donation-diary/src/articles/20200108-the-recipient-needs-your-help.md" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-articles-20200108-the-recipient-needs-your-help-md" */),
  "component---src-templates-article-template-tsx-content-file-path-src-articles-20200114-the-countdown-begins-md": () => import("./../../../src/templates/articleTemplate.tsx?__contentFilePath=/home/runner/work/kidney-donation-diary/kidney-donation-diary/src/articles/20200114-the-countdown-begins.md" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-articles-20200114-the-countdown-begins-md" */),
  "component---src-templates-article-template-tsx-content-file-path-src-articles-20200120-post-surgery-update-finally-mdx": () => import("./../../../src/templates/articleTemplate.tsx?__contentFilePath=/home/runner/work/kidney-donation-diary/kidney-donation-diary/src/articles/20200120-post-surgery-update-finally.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-articles-20200120-post-surgery-update-finally-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-articles-20200122-my-time-at-the-transplant-house-so-far-md": () => import("./../../../src/templates/articleTemplate.tsx?__contentFilePath=/home/runner/work/kidney-donation-diary/kidney-donation-diary/src/articles/20200122-my-time-at-the-transplant-house-so-far.md" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-articles-20200122-my-time-at-the-transplant-house-so-far-md" */),
  "component---src-templates-article-template-tsx-content-file-path-src-articles-20200125-transplant-house-update-2-md": () => import("./../../../src/templates/articleTemplate.tsx?__contentFilePath=/home/runner/work/kidney-donation-diary/kidney-donation-diary/src/articles/20200125-transplant-house-update-2.md" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-articles-20200125-transplant-house-update-2-md" */),
  "component---src-templates-article-template-tsx-content-file-path-src-articles-20200127-finished-the-first-follow-up-appointment-mdx": () => import("./../../../src/templates/articleTemplate.tsx?__contentFilePath=/home/runner/work/kidney-donation-diary/kidney-donation-diary/src/articles/20200127-finished-the-first-follow-up-appointment.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-articles-20200127-finished-the-first-follow-up-appointment-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-articles-20200202-no-good-deed-goes-unpunished-md": () => import("./../../../src/templates/articleTemplate.tsx?__contentFilePath=/home/runner/work/kidney-donation-diary/kidney-donation-diary/src/articles/20200202-no-good-deed-goes-unpunished.md" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-articles-20200202-no-good-deed-goes-unpunished-md" */),
  "component---src-templates-article-template-tsx-content-file-path-src-articles-20200204-finally-back-home-md": () => import("./../../../src/templates/articleTemplate.tsx?__contentFilePath=/home/runner/work/kidney-donation-diary/kidney-donation-diary/src/articles/20200204-finally-back-home.md" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-articles-20200204-finally-back-home-md" */),
  "component---src-templates-article-template-tsx-content-file-path-src-articles-20200211-guest-post-my-wife-jill-md": () => import("./../../../src/templates/articleTemplate.tsx?__contentFilePath=/home/runner/work/kidney-donation-diary/kidney-donation-diary/src/articles/20200211-guest-post-my-wife-jill.md" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-articles-20200211-guest-post-my-wife-jill-md" */),
  "component---src-templates-article-template-tsx-content-file-path-src-articles-20200220-appendix-follow-up-and-2nd-kidney-follow-up-md": () => import("./../../../src/templates/articleTemplate.tsx?__contentFilePath=/home/runner/work/kidney-donation-diary/kidney-donation-diary/src/articles/20200220-appendix-follow-up-and-2nd-kidney-follow-up.md" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-articles-20200220-appendix-follow-up-and-2nd-kidney-follow-up-md" */),
  "component---src-templates-article-template-tsx-content-file-path-src-articles-20200304-7-week-update-md": () => import("./../../../src/templates/articleTemplate.tsx?__contentFilePath=/home/runner/work/kidney-donation-diary/kidney-donation-diary/src/articles/20200304-7-week-update.md" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-articles-20200304-7-week-update-md" */),
  "component---src-templates-article-template-tsx-content-file-path-src-articles-20200328-3-weeks-back-to-work-md": () => import("./../../../src/templates/articleTemplate.tsx?__contentFilePath=/home/runner/work/kidney-donation-diary/kidney-donation-diary/src/articles/20200328-3-weeks-back-to-work.md" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-articles-20200328-3-weeks-back-to-work-md" */),
  "component---src-templates-article-template-tsx-content-file-path-src-articles-20200722-6-month-update-md": () => import("./../../../src/templates/articleTemplate.tsx?__contentFilePath=/home/runner/work/kidney-donation-diary/kidney-donation-diary/src/articles/20200722-6-month-update.md" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-articles-20200722-6-month-update-md" */),
  "component---src-templates-article-template-tsx-content-file-path-src-articles-20201227-meeting-my-recipient-md": () => import("./../../../src/templates/articleTemplate.tsx?__contentFilePath=/home/runner/work/kidney-donation-diary/kidney-donation-diary/src/articles/20201227-meeting-my-recipient.md" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-articles-20201227-meeting-my-recipient-md" */),
  "component---src-templates-article-template-tsx-content-file-path-src-articles-20210122-happy-kidneyversary-md": () => import("./../../../src/templates/articleTemplate.tsx?__contentFilePath=/home/runner/work/kidney-donation-diary/kidney-donation-diary/src/articles/20210122-happy-kidneyversary.md" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-articles-20210122-happy-kidneyversary-md" */),
  "component---src-templates-article-template-tsx-content-file-path-src-articles-20210417-springtime-updates-md": () => import("./../../../src/templates/articleTemplate.tsx?__contentFilePath=/home/runner/work/kidney-donation-diary/kidney-donation-diary/src/articles/20210417-springtime-updates.md" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-articles-20210417-springtime-updates-md" */),
  "component---src-templates-article-template-tsx-content-file-path-src-articles-20210918-belated-18-month-kidneyversary-md": () => import("./../../../src/templates/articleTemplate.tsx?__contentFilePath=/home/runner/work/kidney-donation-diary/kidney-donation-diary/src/articles/20210918-belated-18-month-kidneyversary.md" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-articles-20210918-belated-18-month-kidneyversary-md" */),
  "component---src-templates-article-template-tsx-content-file-path-src-articles-20211214-fall-update-md": () => import("./../../../src/templates/articleTemplate.tsx?__contentFilePath=/home/runner/work/kidney-donation-diary/kidney-donation-diary/src/articles/20211214-fall-update.md" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-articles-20211214-fall-update-md" */),
  "component---src-templates-article-template-tsx-content-file-path-src-articles-20220325-2-year-kidneyversary-update-md": () => import("./../../../src/templates/articleTemplate.tsx?__contentFilePath=/home/runner/work/kidney-donation-diary/kidney-donation-diary/src/articles/20220325-2-year-kidneyversary-update.md" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-articles-20220325-2-year-kidneyversary-update-md" */)
}

